import { combineReducers } from 'redux';
import formReducer from './formReducer';
import metaReducer from './metaReducer';
import pageReducerr from './pageReducer';

export default combineReducers({
  formState: formReducer,
  meta: metaReducer,
  page: pageReducerr,
});
