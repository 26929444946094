const initialValues = {
  session_id: '',
  user_agent: '',
  ip_address: '',
  sub_id1: '',
  sub_id2: '',
  sub_id3: '',
  client_name: '',
  tcpa_disclosure: '1',
  tcpa_consent_language: `By completing the contact form above and clicking "Get My Quote Now", you provide an electronic signature agreeing to the following: "I consent to receive emails and calls about Medicare coverage options, including Medicare Advantage or Medicare Supplement Insurance (which may be auto-dialed, use artificial or pre-recorded voices, and/or be text messages) from these companies and their agents to the email address and telephone number(s) I have provided (even if these numbers are wireless or on a government do-not-call registry). I understand that my consent to receive calls is not required in order to purchase any property, goods or services. My telephone company may impose additional charges for messages. I may revoke my consent to receiving messages at any time. By submitting my information, I confirm that I understand and agree to these Terms of Use and Privacy Policy." You will be directed to a licensed insurance agent who can answer your questions and provide information about Medicare Supplement Insurance Plan, Medicare Advantage and/or Prescription Drug Plan options. Licensed insurance agents are not connected with or endorsed by the U.S. government or the federal Medicare program.`,
  landing_url: `${process.env.GATSBY_WEB_SITE_URL}`,
  privacy_policy_url: `${process.env.GATSBY_POLICY_URL}`,
  offer_id: '',
};

export default (state = initialValues, action) => {
  switch (action.type) {
    case 'SET_AFF_PARAMS':
      return {
        ...state,
        sub_id1: state.sub_id1 || action.payload.sub_id1,
        sub_id2: state.sub_id2 || action.payload.sub_id2,
        sub_id3: state.sub_id3 || action.payload.sub_id3,
        client_name: state.client_name || action.payload.client_name,
        offer_id: state.offer_id || action.payload.offer_id,
      };
    case 'SET_IP_ADDRESS':
      return {
        ...state,
        ip_address: action.payload.ip_address,
      };
    case 'SET_USER_AGENT':
      return {
        ...state,
        user_agent: action.payload.user_agent,
      };
    default:
      return state;
  }
};
