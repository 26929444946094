const initialState = {
  pageIndex: 1,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'NEXT_PAGE':
      return {
        ...state,
        pageIndex: state.pageIndex + 1,
      };
    case 'PREV_PAGE':
      return {
        pageIndex: state.pageIndex - 1 < 1 ? 1 : state.pageIndex - 1,
      };
    case 'RESET_PAGE':
      return {
        pageIndex: 1,
      };
    default:
      return state;
  }
};
