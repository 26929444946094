const initialValues = {
  household_size: '1',
  existing_conditions: 'none',
  gender: '',
  dob: '',
  name_first: '',
  name_last: '',
  home_street: '',
  home_zip: '',
  home_state: '',
  home_city: '',
  email: '',
  phone_home: '',
  trusted_form_url: '',
  income: 45000,
  height_feet: '',
  height_inches: '',
  weight: '',
  smoker: '0',
  expectant_parent: '0',
  life_event: 'lose60',
  previously_denied: '1',
  currently_insured: '0',
  dateOfBirth: '',
};

export default (state = initialValues, action) => {
  switch (action.type) {
    case 'SET_FIELD':
      const { name, value } = action.payload;
      return {
        ...state,
        [name]: value,
      };
    case 'SET_HOME_CITY_STATE':
      return {
        ...state,
        home_city: action.payload.home_city,
        home_state: action.payload.home_state,
      };
    case 'CLEAR_HOME_CITY_STATE':
      return {
        ...state,
        home_city: action.payload.home_city,
        home_state: action.payload.home_state,
      };
    default:
      return state;
  }
};
